export default {
  translation: {
		home: {
			viewmore: "ดูเพิ่มเติม"
		},
		nav: {
			btnLogin: "เข้าสู่ระบบ",
			btnSetting: "ตั้งค่าโปรไฟล์",
			btnChangePassword: "เปลี่ยนรหัสผ่าน",
			btnLogout: "ออกจากระบบ"
		},
		sidebar: {
			home: "หน้าแรก",
			livestream: "ถ่ายทอดสด",
			watchlater: "รายการย้อนหลัง",
			radio: "สถานีวิทยุบนแผนที่",
			channel: "ช่องโทรทัศน์",
			social: "โซเชียลมีเดีย",
			api: "บริการข้อมูล API",
			schedule: "ตารางออกอากาศ",
			history: "ประวัติเข้าชม",
			favorite: "วิดีโอที่ชื่นชอบ",
			setting: "ตั้งค่า",
			logout: "ออกจากระบบ"
		},
		menu: {
			home: "หน้าแรก",
			livestream: "ถ่ายทอดสด",
			watchlater: "รายการแนะนำ",
			radio: "สถานีวิทยุบนแผนที่",
			channel: "ช่องโทรทัศน์",
			social: "โซเชียลมีเดีย",
			schedule: "ตารางออกอากาศ",
			history: "ประวัติเข้าชม",
			favorite: "วิดีโอที่ชื่นชอบ",
			setting: "ตั้งค่า",
			logout: "ออกจากระบบ",
			upload: "อัพโหลด",
			popularUpload: "Popular Uploads",
			playlist: "เพลย์ลิสต์",
			about: "เกี่ยวกับเรา",
			vlog: "วีล็อก",
			podcast: "พอดแคสต์",
			newsfeed: "ข่าวสาร",
			nbtworld: "รายการข่าวต่างประเทศ",
			nbtnews: "รายการ/ข่าว NBT",
			radio: "ฟังวิทยุออนไลน์",
			search: "การค้นหา",
			sawasdee: "สวัสดีประเทศไทย",
			program: "รายการ",
			news: "ข่าว"
		},
		profile: {
			name: "ชื่อ-นามสกุล",
			username: "ชื่อผู้ใช้",
			email: "อีเมล",
			mobile: "โทรศัพท์",
			gender: "เพศ",
			language: "ตั้งค่าเมนูภาษา",
			changepassword: "เปลี่ยนรหัสผ่าน",
			editprofile: "แก้ไขโปรไฟล์"
		}
  },
};
