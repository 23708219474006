export default {
  translation: {
		home: {
			viewmore: "VIEW MORE"
		},
		nav: {
			btnLogin: "Login",
			btnSetting: "Profile Settings",
			btnChangePassword: "Change password",
			btnLogout: "Logout"
		},
		sidebar: {
			home: "Home",
			livestream: "Live Streams",
			watchlater: "Video on-demand",
			radio: "Radio Geo Map",
			channel: "Channel TV",
			schedule: "EPG Schedule",
			social: "Social Media",
			api: "API Public",
			history: "History",
			favorite: "Favorite",
			setting: "Setting",
			logout: "Logout"
		},
		menu: {
			home: "Home",
			livestream: "Live Streams",
			watchlater: "Recommend",
			radio: "Radio Geo Map",
			channel: "TV Channel",
			schedule: "EPG Schedule",
			social: "Social Media",
			history: "History",
			favorite: "Favorite",
			setting: "Setting",
			logout: "Logout",
			upload: "Uploads",
			popularUpload: "Popular Uploads",
			playlist: "Playlists",
			about: "About",
			vlog: "Vlog",
			podcast: "Podcast",
			newsfeed: "News Feed",
			nbtworld: "NBT World News",
			nbtnews: "NBT News",
			radio: "Radio Online",
			search: "Search",
			sawasdee: "Sawasdee Thailand",
			program: "TV Program",
			news: "News"
		},
		profile: {
			name: "First name Last name",
			username: "Username",
			email: "Email",
			mobile: "Mobile Phone",
			gender: "Gender",
			language: "Language",
			changepassword: "Change Password",
			editprofile: "Profile Settings"
		}
  },
};
