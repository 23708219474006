import React from 'react'
import ReactGA from 'react-ga4';
import { Routes, Route, Switch, useLocation, withRouter } from 'react-router-dom'
import loadable, { lazy } from '@loadable/component'
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
const cookies = new Cookies();

moment.updateLocale('th', {
  months : 'มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม'.split('_'),
  relativeTime : {
      future: "%s",
      past:   "%s",
      s  : 'ไม่กี่วินาทีที่แล้ว',
      ss : 'เมื่อ %d วินาทีที่แล้ว',
      m:  "เมื่อนาทีที่แล้ว",
      mm: "เมื่อ %d นาทีที่แล้ว",
      h:  "เมื่อชั่วโมงที่แล้ว",
      hh: "เมื่อ %d ชั่วโมงที่แล้ว",
      d:  "เมื่อวานนี้",
      dd: "เมื่อ %d วันที่แล้ว",
      w:  "เมื่อสัปดาห์ที่แล้ว",
      ww: "เมื่อ %d สัปดาห์ที่แล้ว",
      M:  "เมื่อเดือนที่แล้ว",
      MM: "เมื่อ %d เดือนที่แล้ว",
      y:  "เมื่อปีที่แล้ว",
      yy: "เมื่อ %d ปีที่แล้ว"
  }
});

function generateClientId() {
  const existingClientId = cookies.get('clientId');
  if (existingClientId) {
    return existingClientId;
  }
  const newClientId = uuidv4();
  cookies.set("clientId", newClientId, { path: "/" });
  return newClientId;
}

const App = () => {

  React.useEffect(() => {
    ReactGA.initialize('G-RD8VJ429RE');
  }, []);
  
  const Home = loadable(() => import('./pages/Home.page'))
  const LiveStream = loadable(() => import('./pages/LiveStreams.page'))
  const Vod = loadable(() => import('./pages/Vod.page'))
  const TVGuide = loadable(() => import('./pages/TVGuide.page'))
  const Channel = loadable(() => import('./pages/Channel.page'))
  const Profile = loadable(() => import('./pages/Profile.page'))
  const RadioMap = loadable(() => import('./pages/RadioMap.page'))
  const Viewmore = loadable(() => import('./pages/Viewmore.page'))
  const Category = loadable(() => import('./pages/Category.page'))
  const Search = loadable(() => import('./pages/Search.page'))
  const Favorite = loadable(() => import('./pages/Favorite.page'))
  const History = loadable(() => import('./pages/History.page'))
  const Contact = loadable(() => import('./pages/Contact.page'))
  const Term = loadable(() => import('./pages/Term.page'))
  const WebPolicy = loadable(() => import('./pages/WebPolicy.page'))
  const SecurityPolicy = loadable(() => import('./pages/SecurityPolicy.page'))
  const PrivacyPolicy = loadable(() => import('./pages/PrivacyPolicy.page'))
  const Disclaimer = loadable(() => import('./pages/Disclaimer.page'))
  const CookiePolicy = loadable(() => import('./pages/CookiePolicy.page'))
  const Api = loadable(() => import('./pages/Api.page'))
  const Social = loadable(() => import('./pages/SocialMedia.page'))
  const NotFound = loadable(() => import('./pages/NotFound.page'))
  const InternetServerError = loadable(() => import('./pages/ServerError.page'))
  const Test = loadable(() => import('./pages/Test.page'))

  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home fallback={<div>Loading...</div>} />} />
        <Route path='/live/:id' element={<LiveStream fallback={<div>Loading...</div>} />} />
        <Route path='/vod/:id' element={<Vod fallback={<div>Loading...</div>} />} />
        <Route path='/schedule' element={<TVGuide fallback={<div>Loading...</div>} />} />
        <Route path='/radio' element={<RadioMap fallback={<div>Loading...</div>} />} />
        <Route path='/channel/:id' element={<Channel fallback={<div>Loading...</div>} />} />
        <Route path='/profile' element={<Profile fallback={<div>Loading...</div>} />} />
        <Route path='/viewmore/:id' element={<Viewmore fallback={<div>Loading...</div>} />} />
        <Route path='/category/:id' element={<Category fallback={<div>Loading...</div>} />} />
        <Route path='/search' element={<Search fallback={<div>Loading...</div>} />} />
        <Route path='/history' element={<History fallback={<div>Loading...</div>} />} />
        <Route path='/favorite' element={<Favorite fallback={<div>Loading...</div>} />} />
        <Route path='/social' element={<Social fallback={<div>Loading...</div>} />} />
        <Route path='/contact' element={<Contact fallback={<div>Loading...</div>} />} />
        <Route path='/termandconditions' element={<Term fallback={<div>Loading...</div>} />} />
        <Route path='/webpolicy' element={<WebPolicy fallback={<div>Loading...</div>} />} />
        <Route path='/securitypolicy' element={<SecurityPolicy fallback={<div>Loading...</div>} />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy fallback={<div>Loading...</div>} />} />
        <Route path='/disclaimer' element={<Disclaimer fallback={<div>Loading...</div>} />} />
        <Route path='/cookiespolicy' element={<CookiePolicy fallback={<div>Loading...</div>} />} />
        <Route path='/apipublic' element={<Api fallback={<div>Loading...</div>} />} />
        <Route path='/500' element={<InternetServerError fallback={<div>Loading...</div>} />} />
        <Route path='/test' element={<Test fallback={<div>Loading...</div>} />} />
        <Route path='*' element={<NotFound fallback={<div>Loading...</div>} />} />
      </Routes>
    </div>
  )
}

export default App;