import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLocale from './locales/en'
import thLocale from './locales/th'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const resources = {
  en: {
		...enLocale
	},
  th: {
		...thLocale
  }
};

// console.log(cookies.get('language'));
if (!cookies.get('language')) {
	cookies.set('language', 'en', { path: '/' })
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: cookies.get('language'),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;